import React, { Component } from "react";
import { Link, Routes, Route } from "react-router-dom";
import { ReactComponent as CirkelVerhalenModel } from "../../assets/digicirkel_fase_0.svg";
import CirkelAlleDelenPad from "./cirkeldelen/CirkelAlleDelenPad";
import CirkelDeelPad from "./cirkeldelen/CirkelDeelPad";

class CirkelDelenPad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDeel: 0,
      verhaaldelenFocus: [
        {
          nummer: 1,
          pathClass: ".cls-3",
          status: "normal",
          path: "/cirkel/delen-pad/1",
        },
        {
          nummer: 2,
          pathClass: ".cls-4",
          status: "normal",
          path: "/cirkel/delen-pad/2",
        },
        {
          nummer: 3,
          pathClass: ".cls-6",
          status: "normal",
          path: "/cirkel/delen-pad/3",
        },
        {
          nummer: 4,
          pathClass: ".cls-5",
          status: "normal",
          path: "/cirkel/delen-pad/4",
        },
        {
          nummer: 5,
          pathClass: ".cls-2",
          status: "normal",
          path: "/cirkel/delen-pad/5",
        },
      ],
    };
  }

  checkFocus = () => {
    if (this.state.activeDeel !== 0) {
      this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
        if (this.state.activeDeel === verhaaldeel.nummer) {
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.add("verhaaldeel--normal");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.add("verhaaldeel--normal-important");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--noFocus");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--focus");
        } else {
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.add("verhaaldeel--noFocus");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--normal");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--focus");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--normal-important");
        }
      });
    } else {
      // Als de hele cirkel zichtbaar moet zijn
      this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--normal");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal-important");
      });
    }
  };

  componentDidMount() {
    this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
      document
        .querySelector(verhaaldeel.pathClass)
        .addEventListener("click", () => {
          window.location = `#${verhaaldeel.path}`;
          this.setState({ activeDeel: verhaaldeel.nummer });
        });
    });
  }
  componentDidUpdate() {
    this.checkFocus();
  }

  handleHover = (pathClassFocused) => {
    this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
      if (verhaaldeel.pathClass === pathClassFocused) {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--focus");
      } else {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--noFocus");
      }
    });
  };

  handleOnMouseLeave = (pathClassFocused) => {
    this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
      if (verhaaldeel.pathClass === pathClassFocused) {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--normal");
      } else {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal");
      }
    });
    this.checkFocus();
  };

  render() {
    const activeDeel = this.state.activeDeel;

    return (
      <div>
        <div className="cirkel">
          <div className="cirkel__model cirkel__model--delen">
            <CirkelVerhalenModel className="svg-verhalen"></CirkelVerhalenModel>
            <Link
              to="/cirkel/delen-pad/1"
              onMouseEnter={() => this.handleHover(".cls-3")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-3")}
              className={`btn--blue btn--blue1 ${
                activeDeel === 1 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 1 })}
            >
              1
            </Link>
            <Link
              to="/cirkel/delen-pad/2"
              onMouseEnter={() => this.handleHover(".cls-4")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-4")}
              className={`btn--blue btn--blue2 ${
                activeDeel === 2 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 2 })}
            >
              2
            </Link>
            <Link
              to="/cirkel/delen-pad/3"
              onMouseEnter={() => this.handleHover(".cls-6")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-6")}
              className={`btn--blue btn--blue3 ${
                activeDeel === 3 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 3 })}
            >
              3
            </Link>
            <Link
              to="/cirkel/delen-pad/4"
              className="btn--blue btn--blue4"
              onMouseEnter={() => this.handleHover(".cls-5")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-5")}
              className={`btn--blue btn--blue4 ${
                activeDeel === 4 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 4 })}
            >
              4
            </Link>
            <Link
              to="/cirkel/delen-pad/5"
              onMouseEnter={() => this.handleHover(".cls-2")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-2")}
              className={`btn--blue btn--blue5 ${
                activeDeel === 5 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 5 })}
            >
              5
            </Link>
            <div className="cirkel__model--logo"></div>
            <div className="cirkel__model--divider"></div>
          </div>
        </div>
        {/* 
        <div className="uitleg">
          <div className="uitleg__info">
            <h1 className="uitleg__titel">
              <span className="whiteHighlight whiteHighlight--small">5</span>{" "}
              verhaaldelen
            </h1>
            <p className="uitleg__tekst">
              Elk heldenverhaal bestaat uit{" "}
              <span className="uitleg__tekst--bold">5 delen</span>.<br></br>
            </p>
            <p className="uitleg__tekst">
              Deze verhaaldelen maken het avontuur van de held herkenbaar én
              boeiend.
            </p>
            <h5 className="uitleg__call-to-action">
              Klik op{" "}
              <span className="whiteHighlight whiteHighlight--medium">
                cirkeldelen
              </span>
              <br></br> voor meer info!
            </h5>
          </div>
          <div className="btn-group">
            <Link to="/cirkel/tijd" className="btn--red uitleg__btn">
              <i className="icon-angle-left"></i>
              Vorige
            </Link>
            <Link to="/cirkel/conclusie" className="btn--red uitleg__btn">
              Besluit
              <i className="icon-angle-right"></i>
            </Link>
          </div>
        </div>
      */}
        <Routes>
          <Route path="*" element={<CirkelAlleDelenPad />}></Route>
          <Route exact path="1" element={<CirkelDeelPad activeDeel={1} />} />
          <Route exact path="2" element={<CirkelDeelPad activeDeel={2} />} />
          <Route exact path="3" element={<CirkelDeelPad activeDeel={3} />} />
          <Route exact path="4" element={<CirkelDeelPad activeDeel={4} />} />
          <Route exact path="5" element={<CirkelDeelPad activeDeel={5} />} />
        </Routes>
      </div>
    );
  }
}

export default CirkelDelenPad;
