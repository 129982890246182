import React, { Component } from "react";
import logo from "../../../assets/cirkelhelden_logo.svg";

class Bron4 extends Component {
  render() {
    return (
      <div className="bron">
        <div className="bron__header">
          <img src={logo} alt="Logo cirkelhelden" />{" "}
          <h2 className="bron__titel">Hoek 4</h2>
        </div>
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/o0aZ2zVSwMY?start=6&end=61&autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Bron4;
