import React, { Component } from "react";
import logo from "../../../assets/cirkelhelden_logo.svg";
import video from "../../../assets/video/CH_Helias_snippet.mp4";

class Bron2 extends Component {
  render() {
    return (
      <div className="bron">
        <div className="bron__header">
          <img src={logo} alt="Logo cirkelhelden" />{" "}
          <h2 className="bron__titel">Hoek 2</h2>
        </div>
        <div className="video-container">
          <iframe
            src={video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Bron2;
