import React, { Component } from "react";
import { Link } from "react-router-dom";
import { inspiratieImages, inspiratieWoorden } from "./inspiratieImages";
import InspiratieModals from "./InspiratieModals";
import { ReactComponent as CirkelVerhalenModel } from "../../../assets/digicirkel_fase_0.svg";

// Imports iconen ingrediënten
import iconHeld from "../../../assets/ingredienten_icons/held.svg";
import iconPlek from "../../../assets/ingredienten_icons/plek.svg";
import iconAvontuur from "../../../assets/ingredienten_icons/avontuur.svg";
import iconKracht from "../../../assets/ingredienten_icons/kracht.svg";
import iconVijand from "../../../assets/ingredienten_icons/vijand.svg";
import iconObstakel from "../../../assets/ingredienten_icons/obstakel.svg";
import iconWeerzien from "../../../assets/ingredienten_icons/weerzien.svg";
import iconVerandering from "../../../assets/ingredienten_icons/verandering.svg";
import iconInzicht from "../../../assets/ingredienten_icons/inzicht.svg";
import iconOverwinning from "../../../assets/ingredienten_icons/overwinning.svg";
import iconStartschot from "../../../assets/ingredienten_icons/startschot.svg";
import iconHulp from "../../../assets/ingredienten_icons/hulp.svg";
import iconVoorwerp from "../../../assets/ingredienten_icons/voorwerp.svg";
import iconPech from "../../../assets/ingredienten_icons/pech.svg";
import iconGevoel from "../../../assets/ingredienten_icons/gevoel.svg";

class CirkelDeelVrij extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeDeel: window.location.href.split("/").pop(),
      activeIngredient: 0,
      verhaaldelenFocus: [
        {
          nummer: 1,
          pathClass: ".cls-3",
          status: "normal",
          path: "/cirkel/delen-vrij/1",
        },
        {
          nummer: 2,
          pathClass: ".cls-4",
          status: "normal",
          path: "/cirkel/delen-vrij/2",
        },
        {
          nummer: 3,
          pathClass: ".cls-6",
          status: "normal",
          path: "/cirkel/delen-vrij/3",
        },
        {
          nummer: 4,
          pathClass: ".cls-5",
          status: "normal",
          path: "/cirkel/delen-vrij/4",
        },
        {
          nummer: 5,
          pathClass: ".cls-2",
          status: "normal",
          path: "/cirkel/delen-vrij/5",
        },
      ],
      verhaaldelen: [
        {
          titel: "1. Hupla, <br>op avontuur!",
          subtitel: "Over een probleem dat je op weg stuurt",
          tekst: `Cirkelhelden stoten op een <span class="verhaaldeel__tekst--emphasis">probleem</span> dat ze moeten oplossen. Daarvoor vertrekken ze op avontuur, weg van hun vertrouwde omgeving.`,
          ingredienten: [
            {
              titel: "Held",
              tekst: "Wie is de belangrijkste persoon?",
              icon: iconHeld,
              inspiratie: inspiratieImages.held,
              inspiratieWoorden: inspiratieWoorden.held,
            },
            {
              titel: "Plaats",
              tekst: "Vanwaar vertrekt de held op avontuur?",
              icon: iconPlek,
              inspiratie: inspiratieImages.plaats,
              inspiratieWoorden: inspiratieWoorden.plaats,
            },
            {
              titel: "Startschot",
              tekst: "Waarom vertrekt de held op avontuur?",
              icon: iconStartschot,
              inspiratie: inspiratieImages.startschot,
              inspiratieWoorden: inspiratieWoorden.startschot,
            },
          ],
        },
        {
          titel: "2. Toppie, <br>hulp is nabij!",
          subtitel: "Over helpende handen & handige krachten",
          tekst: `Cirkelhelden staan er nooit alleen voor om het probleem op te lossen. Ze krijgen <span class="verhaaldeel__tekst--emphasis">hulp</span>  van mensen rondom zich, en beschikken over handige voorwerpen of (magische) krachten ...`,

          ingredienten: [
            {
              titel: "Hulp",
              tekst: "Wie helpt de held onderweg?",
              icon: iconHulp,
              inspiratie: inspiratieImages.hulp,
              inspiratieWoorden: inspiratieWoorden.hulp,
            },
            {
              titel: "Voorwerp",
              tekst: "Welk speciaal voorwerp gebruikt de held?",
              icon: iconVoorwerp,
              inspiratie: inspiratieImages.voorwerp,
              inspiratieWoorden: inspiratieWoorden.voorwerp,
            },
            {
              titel: "Kracht",
              tekst: "Wat kan de held heel goed?",
              icon: iconKracht,
              inspiratie: inspiratieImages.kracht,
              inspiratieWoorden: inspiratieWoorden.kracht,
            },
          ],
        },

        {
          titel: "3. Brr! Huh? <br>Arrggh! Oeps!",
          subtitel: "Over hindernissen onderweg",
          tekst: `Cirkelhelden beleven verschillende avonturen waarin ze <span class="verhaaldeel__tekst--emphasis">hindernissen</span>  nemen. Ze komen in vervelende, spannende of moeilijke situaties terecht, waaruit ze een uitweg moeten zoeken.`,

          ingredienten: [
            {
              titel: "Avontuur",
              tekst: "Welke avonturen komt de held tegen?",
              icon: iconAvontuur,
              inspiratie: inspiratieImages.avontuur,
              inspiratieWoorden: inspiratieWoorden.avontuur,
            },
            {
              titel: "Vijand",
              tekst: "Welke grote vijand werkt de held tegen?",
              icon: iconVijand,

              inspiratie: inspiratieImages.vijand,
              inspiratieWoorden: inspiratieWoorden.vijand,
            },
            {
              titel: "Pech",
              tekst: "Welke tegenslag krijgt de held te verduren?",
              icon: iconPech,
              inspiratie: inspiratieImages.pech,
              inspiratieWoorden: inspiratieWoorden.pech,
            },
          ],
        },
        {
          titel: "4. Aha! Hoera!",
          subtitel: "Over iets of iemand overwinnen",
          tekst: `Cirkelhelden leren uit wat ze meemaken en worden er sterker van. Alle avonturen leiden uiteindelijk tot een <span class="verhaaldeel__tekst--emphasis">overwinning</span> of tot een belangrijk <span class="verhaaldeel__tekst--emphasis">inzicht</span>  in het leven.`,

          ingredienten: [
            {
              titel: "Obstakel",
              tekst: "Wat is de grootste hindernis voor de held?",
              icon: iconObstakel,
              inspiratie: inspiratieImages.obstakel,
              inspiratieWoorden: inspiratieWoorden.obstakel,
            },
            {
              titel: "Inzicht",
              tekst: "Wat leert de held?",
              icon: iconInzicht,
              inspiratie: inspiratieImages.inzicht,
              inspiratieWoorden: inspiratieWoorden.inzicht,
            },
            {
              titel: "Overwinning",
              tekst: "Hoe overwint de held het obstakel?",
              icon: iconOverwinning,

              inspiratie: inspiratieImages.overwinning,
              inspiratieWoorden: inspiratieWoorden.overwinning,
            },
          ],
        },
        {
          titel: "5. Oef, <br>terug thuis!",
          subtitel: "Over wat het avontuur heeft opgeleverd",
          tekst: `Cirkelhelden nemen wat ze meegemaakt en geleerd hebben mee naar huis. <span class="verhaaldeel__tekst--emphasis">Thuis</span> blijkt alles ofwel zoals vroeger ofwel voorgoed veranderd.`,

          ingredienten: [
            {
              titel: "Weerzien",
              tekst: "Wie wacht de held op bij de terugkeer?",
              icon: iconWeerzien,
              inspiratie: inspiratieImages.weerzien,
              inspiratieWoorden: inspiratieWoorden.weerzien,
            },
            {
              titel: "Gevoel",
              tekst: "Hoe voelt de held zich bij de terugkeer?",
              icon: iconGevoel,
              inspiratie: inspiratieImages.gevoel,
              inspiratieWoorden: inspiratieWoorden.gevoel,
            },
            {
              titel: "Verandering",
              tekst: "Wat is er thuis veranderd, vergeleken bij vroeger?",
              icon: iconVerandering,

              inspiratie: inspiratieImages.verandering,
              inspiratieWoorden: inspiratieWoorden.verandering,
            },
          ],
        },
      ],
    };
  }

  render() {
    const activeDeel = this.props.activeDeel;
    const verhaaldeel = this.state.verhaaldelen[activeDeel - 1];
    const ingredienten = verhaaldeel.ingredienten;
    const activeIngredient = this.state.activeIngredient;

    return (
      <div>
        <div className="uitleg uitleg--vrij">
          <div className="uitleg__verhaaldeel">
            <Link to="/cirkel/delen-vrij/0" className="btn--x">
              <i className="icon-times"></i>
            </Link>
            <h1
              className="verhaaldeel__titel"
              dangerouslySetInnerHTML={{
                __html: `${verhaaldeel.titel}`,
              }}
            ></h1>
            <div className="verhaaldeel__ingredienten">
              <h2 className="verhaaldeel__subtitel">
                Klik op een ingrediënt voor{" "}
                <span className="verhaaldeel__tekst--emphasis">inspiratie</span>
                .
              </h2>
              {ingredienten.map((ingredient, i) => {
                return (
                  <div
                    key={i}
                    className="verhaaldeel__ingredient"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => this.setState({ activeIngredient: i })}
                  >
                    <div
                      className={`ingredient__icon ingredient__icon--deel${activeDeel}`}
                    >
                      <img alt={ingredient.titel} src={ingredient.icon}></img>
                    </div>

                    <div className="ingredient__info">
                      <h4 className="ingredient__titel">{ingredient.titel}</h4>
                      <p
                        className="ingredient__tekst"
                        dangerouslySetInnerHTML={{
                          __html: `${ingredient.tekst}`,
                        }}
                      ></p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <InspiratieModals
          verhaaldeel={verhaaldeel}
          ingredient={ingredienten[activeIngredient]}
        />
      </div>
    );
  }
}

export default CirkelDeelVrij;
