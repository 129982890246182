import React, { Component } from "react";
import "../../scss/cirkeltool.scss";
import { Route, Routes } from "react-router-dom";
import CirkelMain from "./CirkelMain";
import CirkelVerhaal from "./CirkelVerhaal";
import CirkelTijd from "./CirkelTijd";
import Cirkeltjes from "./Cirkeltjes";
import CirkelDelenPad from "./CirkelDelenPad";
import CirkelDelenVrij from "./CirkelDelenVrij";
import Page404 from "../../pages/Page404";
import CirkelConclusie from "./CirkelConclusie";
import DeviceModal from "./DeviceModal";

class Cirkeltool extends Component {
  render() {
    return (
      <div id="cirkeltool">
        <DeviceModal />
        <Cirkeltjes></Cirkeltjes>
        <Routes>
          <Route path="/" element={<CirkelMain />} />
          <Route path="/verhaal" element={<CirkelVerhaal />} />
          <Route path="/tijd" element={<CirkelTijd />} />
          <Route path="/delen-pad/*" element={<CirkelDelenPad />}></Route>
          <Route path="/delen-vrij/*" element={<CirkelDelenVrij />}></Route>

          <Route path="/conclusie" element={<CirkelConclusie />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <DeviceModal />
      </div>
    );
  }
}

export default Cirkeltool;
