import React, { Component } from "react";
import cirkeltje1 from "../../assets/cirkel_geel.svg";
import cirkeltje2 from "../../assets/cirkel_oranje_1.svg";
import cirkeltje3 from "../../assets/cirkel_oranje_2.svg";
import cirkeltje4 from "../../assets/cirkel_rood.svg";
import cirkeltje5 from "../../assets/cirkel_roze.svg";

class Cirkeltjes extends Component {
  render() {
    return (
      <div className="cirkeltjes">
        <img className="cirkeltje1" alt="cirkeltje" src={cirkeltje1}></img>
        <img className="cirkeltje2" alt="cirkeltje" src={cirkeltje2}></img>
        <img className="cirkeltje3" alt="cirkeltje" src={cirkeltje3}></img>
        <img className="cirkeltje4" alt="cirkeltje" src={cirkeltje4}></img>
        <img className="cirkeltje5" alt="cirkeltje" src={cirkeltje5}></img>
      </div>
    );
  }
}

export default Cirkeltjes;
