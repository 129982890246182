import React, { Component } from "react";
import { Link } from "react-router-dom";

class CirkelConclusie extends Component {
  render() {
    return (
      <div>
        <div className="cirkel">
          <div className="cirkel__model cirkel__model--conclusie">
            <div className="cirkel__model--logo"></div>
            <div className="cirkel__model--divider"></div>
          </div>
        </div>
        <div className="uitleg">
          <div className="uitleg__info">
            <h1 className="uitleg__titel">
              Helden zijn<br></br>
              <span className="whiteHighlight">tijdloos</span>
              <br></br>en onmisbaar!
            </h1>
            <p className="uitleg__tekst">
              <strong>Heldenverhalen</strong> spreken tot de verbeelding,
              bevatten vaak dezelfde <strong>verhaaldelen</strong> en zijn van
              alle <strong>tijden</strong>.
            </p>
            <Link
              className="uitleg__tekst call-to-action__tekst"
              to="/cirkel/delen-vrij/0"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              <span>Maak nu zelf een heldenverhaal.</span>
              <i className="icon-external-link"></i>
            </Link>
          </div>
          <div className="btn-group">
            <Link to="/cirkel/delen-pad/0" className="btn--red uitleg__btn">
              <i className="icon-angle-left"></i>
              Vorige
            </Link>

            <Link
              to="/cirkel/delen-vrij/0"
              rel="noopener noreferrer"
              target="_blank"
              className="btn--red uitleg__btn"
              style={{ visibility: "hidden" }}
            >
              <i className="icon-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CirkelConclusie;
