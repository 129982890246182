import held1 from "../../../assets/inspiratie/01_held/held1.jpg";
import held2 from "../../../assets/inspiratie/01_held/held2.jpg";
import held3 from "../../../assets/inspiratie/01_held/held3.jpg";
import held4 from "../../../assets/inspiratie/01_held/held4.jpg";
import held5 from "../../../assets/inspiratie/01_held/held5.jpg";
import held6 from "../../../assets/inspiratie/01_held/held6.jpg";
import held7 from "../../../assets/inspiratie/01_held/held7.jpg";
import held8 from "../../../assets/inspiratie/01_held/held8.jpg";
import held9 from "../../../assets/inspiratie/01_held/held9.jpg";
import held10 from "../../../assets/inspiratie/01_held/held10.jpg";
import held11 from "../../../assets/inspiratie/01_held/held11.jpg";
import held12 from "../../../assets/inspiratie/01_held/held12.jpg";

import plaats1 from "../../../assets/inspiratie/02_plaats/plaats1.jpg";
import plaats2 from "../../../assets/inspiratie/02_plaats/plaats2.jpg";
import plaats3 from "../../../assets/inspiratie/02_plaats/plaats3.jpg";
import plaats4 from "../../../assets/inspiratie/02_plaats/plaats4.jpg";
import plaats5 from "../../../assets/inspiratie/02_plaats/plaats5.jpg";
import plaats6 from "../../../assets/inspiratie/02_plaats/plaats6.jpg";
import plaats7 from "../../../assets/inspiratie/02_plaats/plaats7.jpg";
import plaats8 from "../../../assets/inspiratie/02_plaats/plaats8.jpg";
import plaats9 from "../../../assets/inspiratie/02_plaats/plaats9.jpg";

import hulp1 from "../../../assets/inspiratie/04_hulp/hulp1.jpg";
import hulp2 from "../../../assets/inspiratie/04_hulp/hulp2.jpg";
import hulp3 from "../../../assets/inspiratie/04_hulp/hulp3.jpg";
import hulp4 from "../../../assets/inspiratie/04_hulp/hulp4.jpg";
import hulp5 from "../../../assets/inspiratie/04_hulp/hulp5.jpg";
import hulp6 from "../../../assets/inspiratie/04_hulp/hulp6.jpg";
import hulp7 from "../../../assets/inspiratie/04_hulp/hulp7.jpg";
import hulp8 from "../../../assets/inspiratie/04_hulp/hulp8.jpg";
import hulp9 from "../../../assets/inspiratie/04_hulp/hulp9.jpg";

import voorwerp1 from "../../../assets/inspiratie/05_voorwerp/voorwerp1.jpg";
import voorwerp2 from "../../../assets/inspiratie/05_voorwerp/voorwerp2.jpg";
import voorwerp3 from "../../../assets/inspiratie/05_voorwerp/voorwerp3.jpg";
import voorwerp4 from "../../../assets/inspiratie/05_voorwerp/voorwerp4.jpg";
import voorwerp5 from "../../../assets/inspiratie/05_voorwerp/voorwerp5.jpg";
import voorwerp6 from "../../../assets/inspiratie/05_voorwerp/voorwerp6.jpg";

import avontuur1 from "../../../assets/inspiratie/07_avontuur/avontuur1.jpg";
import avontuur2 from "../../../assets/inspiratie/07_avontuur/avontuur2.jpg";
import avontuur3 from "../../../assets/inspiratie/07_avontuur/avontuur3.jpg";
import avontuur4 from "../../../assets/inspiratie/07_avontuur/avontuur4.jpg";
import avontuur5 from "../../../assets/inspiratie/07_avontuur/avontuur5.jpg";
import avontuur6 from "../../../assets/inspiratie/07_avontuur/avontuur6.jpg";
import avontuur7 from "../../../assets/inspiratie/07_avontuur/avontuur7.jpg";

import vijand1 from "../../../assets/inspiratie/08_vijand/vijand1.jpg";
import vijand2 from "../../../assets/inspiratie/08_vijand/vijand2.jpg";
import vijand3 from "../../../assets/inspiratie/08_vijand/vijand3.jpg";
import vijand4 from "../../../assets/inspiratie/08_vijand/vijand4.jpg";
import vijand5 from "../../../assets/inspiratie/08_vijand/vijand5.jpg";
import vijand6 from "../../../assets/inspiratie/08_vijand/vijand6.jpg";
import vijand7 from "../../../assets/inspiratie/08_vijand/vijand7.jpg";
import vijand8 from "../../../assets/inspiratie/08_vijand/vijand8.jpg";

import pech1 from "../../../assets/inspiratie/09_pech/pech1.jpg";
import pech2 from "../../../assets/inspiratie/09_pech/pech2.jpg";
import pech3 from "../../../assets/inspiratie/09_pech/pech3.jpg";
import pech4 from "../../../assets/inspiratie/09_pech/pech4.jpg";

import obstakel1 from "../../../assets/inspiratie/10_obstakel/obstakel1.jpg";
import obstakel2 from "../../../assets/inspiratie/10_obstakel/obstakel2.jpg";
import obstakel3 from "../../../assets/inspiratie/10_obstakel/obstakel3.jpg";
import obstakel4 from "../../../assets/inspiratie/10_obstakel/obstakel4.jpg";
import obstakel5 from "../../../assets/inspiratie/10_obstakel/obstakel5.jpg";
import obstakel6 from "../../../assets/inspiratie/10_obstakel/obstakel6.jpg";
import obstakel7 from "../../../assets/inspiratie/10_obstakel/obstakel7.jpg";

import weerzien1 from "../../../assets/inspiratie/13_weerzien/weerzien1.jpg";
import weerzien2 from "../../../assets/inspiratie/13_weerzien/weerzien2.jpg";
import weerzien3 from "../../../assets/inspiratie/13_weerzien/weerzien3.jpg";
import weerzien4 from "../../../assets/inspiratie/13_weerzien/weerzien4.jpg";
import weerzien5 from "../../../assets/inspiratie/13_weerzien/weerzien5.jpg";

export const inspiratieImages = {
  held: [
    held1,
    held2,
    held3,
    held4,
    held5,
    held6,
    held7,
    held8,
    held9,
    held10,
    held11,
    held12,
  ],
  plaats: [
    plaats1,
    plaats2,
    plaats3,
    plaats4,
    plaats5,
    plaats6,
    plaats7,
    plaats8,
    plaats9,
  ],
  startschot: [],
  hulp: [hulp9, hulp8, hulp7, hulp6, hulp5, hulp4, hulp3, hulp2, hulp1],
  voorwerp: [voorwerp1, voorwerp2, voorwerp3, voorwerp4, voorwerp5, voorwerp6],
  kracht: [],
  avontuur: [
    avontuur1,
    avontuur2,
    avontuur3,
    avontuur4,
    avontuur5,
    avontuur6,
    avontuur7,
  ],
  vijand: [
    vijand1,
    vijand2,
    vijand3,
    vijand4,
    vijand5,
    vijand6,
    vijand7,
    vijand8,
  ],
  pech: [pech1, pech2, pech3, pech4],
  obstakel: [
    obstakel1,
    obstakel2,
    obstakel3,
    obstakel4,
    obstakel5,
    obstakel6,
    obstakel7,
  ],
  inzicht: [],
  overwinning: [],
  weerzien: [weerzien1, weerzien2, weerzien3, weerzien4, weerzien5],
  gevoel: [],
  verandering: [],
};

export const inspiratieWoorden = {
  held: [
    "een wandelende boom",
    "een vuilnisvrouw",
    "een sprekende baby",
    "de geest van een familielid",
    "een blinde piloot",
    "een bibliothecaris die alle boeken van de wereld kent",
    "een postbode die e-mails rondbrengt",
  ],
  plaats: [],
  startschot: [
    "een vriend redden",
    "een magische plek vinden",
    "op zoek naar de liefde",
    "vluchtelingen helpen",
    "de held wordt verbannen",
    "een draak verslaan",
    "een vloek opheffen",
    "het huis van de held werd in brand gestoken",
    "een nieuw voorwerp uitvinden",
    "het dorp redden van een natuurramp",
  ],
  hulp: [],
  voorwerp: [],
  kracht: [
    "slim zijn",
    "kalmte bewaren",
    "veel fantasie hebben",
    "super snel lopen",
    "onzichtbaar worden",
    "meer dan 5 talen spreken",
    "goed luisteren naar anderen",
    "enthousiast voorlezen",
    "klein worden als een paperclip",
    "prachtig zingen",
    "dwars door muren kijken",
    "anderen genezen van wonden",
  ],
  avontuur: [],
  vijand: [],
  pech: [
    "een platte band krijgen",
    "een besmettelijk virus oplopen",
    "met je voet in een stront stappen",
    "in een hagelbui terecht komen",
    "een voorwerp verliezen",
    "de batterij van een smartphone is leeg",
    "je eigen naam plots vergeten",
  ],
  obstakel: [],
  inzicht: [
    "Iedereen wil het beste voor elkaar.",
    "Je moet jezelf kunnen zijn.",
    "Meedoen is beter dan winnen.",
    "Wees niet te jaloers.",
    "Nergens beter dan thuis!",
    "Waar een wil is is een weg",
    "Eerlijkheid duurt het langst",
    "Je moet luisteren naar elkaar.",
    "De meeste dromen zijn bedrog.",
  ],
  overwinning: [
    "Je ontdekt een extra kracht.",
    "Je bedenkt een slim plan.",
    "Je verslaat de vijand in een fel duel.",
    "Iets wordt eindelijk teruggevonden.",
    "Je sluit een deal met de vijand.",
    "Je wacht heel heel lang.",
    "Je roept de hulp in van een (half)god.",
    "Je neemt een geneesmiddel in.",
    "Je verkleedt je in iemand anders.",
  ],
  weerzien: [],
  gevoel: [
    "Gelukkig",
    "Trots",
    "Rustig",
    "Zenuwachtig",
    "Eenzaam",
    "Verdrietig",
    "Pijn aan je kleine teen",
    "Opgelucht",
    "Stomverbaasd",
    "Hongerig",
    "Ontspannen",
    "Vereerd",
    "Angstig",
    "Warm",
  ],
  verandering: [
    "De vijand is verslagen.",
    "De personages zijn gelukkig.",
    "Vrede is teruggekeerd.",
    "Iedereen moet naar een andere planeet op zoek",
    "Niemand is meer arm.",
    "Iedereen is gezond.",
    "Iets is voor altijd verloren.",
    "De stad is helemaal groen geworden.",
    "Iedereen verstaat elkaar.",
    "Twee personages trouwen!",
    "Het probleem is opgelost.",
    "Thuis is er nu een nieuwe leider.",
  ],
};
