import React, { Component } from "react";
import { Link } from "react-router-dom";

class CirkelAlleDelenPad extends Component {
  render() {
    return (
      <div className="uitleg">
        <div className="uitleg__info">
          <h1 className="uitleg__titel">
            <span className="whiteHighlight whiteHighlight--small">5</span>{" "}
            verhaaldelen
          </h1>
          <p className="uitleg__tekst">
            Bijna elk heldenverhaal bestaat uit{" "}
            <span className="uitleg__tekst--bold">5 delen</span>.<br></br>
          </p>
          <p className="uitleg__tekst">
            Deze verhaaldelen maken het avontuur van de held herkenbaar én
            boeiend.
          </p>
          <h5 className="uitleg__call-to-action">
            Klik op <span className="btn--blue-fake">de cirkeldelen</span>
            <br></br> voor meer info!
          </h5>
        </div>
        <div className="btn-group">
          <Link to="/cirkel/tijd" className="btn--red uitleg__btn">
            <i className="icon-angle-left"></i>
            Vorige
          </Link>
          <Link to="/cirkel/conclusie" className="btn--red uitleg__btn">
            Besluit
            <i className="icon-angle-right"></i>
          </Link>
        </div>
      </div>
    );
  }
}

export default CirkelAlleDelenPad;
