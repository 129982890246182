import React, { Component } from "react";
import { Link } from "react-router-dom";

class CirkelTijd extends Component {
  render() {
    return (
      <div>
        <div className="cirkel">
          <div className="cirkel__model cirkel__model--tijd">
            <div className="cirkel__model--logo"></div>
            <div className="cirkel__model--pijl"></div>
            <div className="cirkel__model--divider"></div>
          </div>
        </div>
        <div className="uitleg">
          <div className="uitleg__info">
            <h1 className="uitleg__titel">
              Van oud <br></br>naar jong
            </h1>
            <p className="uitleg__tekst">
              De vijf heldenverhalen zijn van vroeger en nu. Het oudste verhaal
              plaatsten we in het midden.
            </p>
            <p className="uitleg__tekst">
              De Erfgoedbibliotheek bewaart verhalen die{" "}
              <span className="uitleg__tekst--bold"> vele eeuwen oud </span>
              zijn, hopelijk voor de eeuwigheid!{" "}
            </p>
          </div>
          <div className="btn-group">
            <Link to="/cirkel/verhaal" className="btn--red uitleg__btn">
              <i className="icon-angle-left"></i>
              Vorige
            </Link>
            <Link to="/cirkel/delen-pad/0" className="btn--red uitleg__btn">
              Volgende
              <i className="icon-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CirkelTijd;
