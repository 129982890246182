import React, { Component } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";

class InspiratieModals extends Component {
  render() {
    const verhaaldeel = this.props.verhaaldeel;
    const ingredient = this.props.ingredient;

    let verhaaldeelTrimmed = verhaaldeel.titel.replaceAll("<br>", "");

    const images = ingredient.inspiratie.map(function (image, i) {
      return (
        <div key={i} className="inspiratieImage__container">
          <img
            src={image}
            className="inspiratieImage"
            alt={"Voorbeeld " + i + " van " + ingredient.titel}
          ></img>
        </div>
      );
    });
    const woorden = ingredient.inspiratieWoorden.map(function (woord, i) {
      return (
        <div key={i} className="inspiratieWoord__container">
          <h2 className="inspiratieWoord">{woord}</h2>
        </div>
      );
    });

    const breakpointColumnsObj = {
      default: 4,
      1300: 3,
      1000: 2,
      600: 1,
    };

    return (
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal__titles">
                <h4 className="modal__verhaaldeel-title">
                  {verhaaldeelTrimmed} <span>></span>
                </h4>
                <h2 className="modal__ingredient-title" id="exampleModalLabel">
                  {ingredient.titel}
                </h2>
              </div>
              <Link to="" className="btn--x btn--x--red" data-dismiss="modal">
                <i className="icon-times"></i>
              </Link>
            </div>
            <div className="modal-body">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="inspiration-masonry"
                columnClassName="inspiration-masonry_column"
              >
                {images}
                {woorden}
              </Masonry>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InspiratieModals;
