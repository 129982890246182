import React, { Component } from "react";
import { Link } from "react-router-dom";

class CirkelMain extends Component {
  render() {
    return (
      <div>
        <div className="cirkel">
          <div className="cirkel__model cirkel__model--main">
            <div className="cirkel__model--logo"></div>
            <div className="cirkel__model--divider"></div>
          </div>
        </div>
        <div className="uitleg">
          <div className="uitleg__info">
            <h1 className="uitleg__titel">
              Wat maakt <br></br>een held een<br></br>{" "}
              <span className="whiteHighlight">Cirkelheld?</span>
            </h1>
          </div>
          <div className="btn-group">
            <Link to="/cirkel/verhaal" className="btn--red uitleg__btn">
              Ontdekt het hier!
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CirkelMain;
