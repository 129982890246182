import React, { Component } from "react";

class Page404 extends Component {
  render() {
    return (
      <div className="404">
        <h1>Oh nee, deze pagina is zoek! </h1>
        <h2>Word jij de held die de pagina terugvindt?</h2>
      </div>
    );
  }
}

export default Page404;
