import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { ReactComponent as CirkelVerhalenModel } from "../../assets/digicirkel_fase_0.svg";
import CirkelAlleDelenVrij from "./cirkeldelen/CirkelAlleDelenVrij";
import CirkelDeelVrij from "./cirkeldelen/CirkelDeelVrij";

class CirkelDelenVrij extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDeel: 0,
      verhaaldelenFocus: [
        {
          nummer: 1,
          pathClass: ".cls-3",
          status: "normal",
          path: "/cirkel/delen-vrij/1",
        },
        {
          nummer: 2,
          pathClass: ".cls-4",
          status: "normal",
          path: "/cirkel/delen-vrij/2",
        },
        {
          nummer: 3,
          pathClass: ".cls-6",
          status: "normal",
          path: "/cirkel/delen-vrij/3",
        },
        {
          nummer: 4,
          pathClass: ".cls-5",
          status: "normal",
          path: "/cirkel/delen-vrij/4",
        },
        {
          nummer: 5,
          pathClass: ".cls-2",
          status: "normal",
          path: "/cirkel/delen-vrij/5",
        },
      ],
    };
  }

  checkFocus = () => {
    if (this.state.activeDeel !== 0) {
      this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
        if (this.state.activeDeel === verhaaldeel.nummer) {
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.add("verhaaldeel--normal");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.add("verhaaldeel--normal-important");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--noFocus");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--focus");
        } else {
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.add("verhaaldeel--noFocus");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--normal");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--focus");
          document
            .querySelector(verhaaldeel.pathClass)
            .classList.remove("verhaaldeel--normal-important");
        }
      });
    } else {
      // Als de hele cirkel zichtbaar moet zijn
      this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--normal");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal-important");
      });
    }
  };

  componentDidMount() {
    this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
      document
        .querySelector(verhaaldeel.pathClass)
        .addEventListener("click", () => {
          window.location = `#${verhaaldeel.path}`;
          this.setState({ activeDeel: verhaaldeel.nummer });
        });
    });
  }
  componentDidUpdate() {
    this.checkFocus();
  }

  handleHover = (pathClassFocused) => {
    this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
      if (verhaaldeel.pathClass === pathClassFocused) {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--focus");
      } else {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--noFocus");
      }
    });
  };

  handleOnMouseLeave = (pathClassFocused) => {
    this.state.verhaaldelenFocus.forEach((verhaaldeel) => {
      if (verhaaldeel.pathClass === pathClassFocused) {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.add("verhaaldeel--normal");
      } else {
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--focus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--noFocus");
        document
          .querySelector(verhaaldeel.pathClass)
          .classList.remove("verhaaldeel--normal");
      }
    });
    this.checkFocus();
  };

  render() {
    const activeDeel = this.state.activeDeel;

    return (
      <div>
        <div className="cirkel">
          <div className="cirkel__model cirkel__model--delen">
            <CirkelVerhalenModel className="svg-verhalen"></CirkelVerhalenModel>
            <Link
              to="/cirkel/delen-vrij/1"
              onMouseEnter={() => this.handleHover(".cls-3")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-3")}
              className={`btn--blue btn--blue1 ${
                activeDeel === 1 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 1 })}
            >
              1
            </Link>
            <Link
              to="/cirkel/delen-vrij/2"
              onMouseEnter={() => this.handleHover(".cls-4")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-4")}
              className={`btn--blue btn--blue2 ${
                activeDeel === 2 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 2 })}
            >
              2
            </Link>
            <Link
              to="/cirkel/delen-vrij/3"
              onMouseEnter={() => this.handleHover(".cls-6")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-6")}
              className={`btn--blue btn--blue3 ${
                activeDeel === 3 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 3 })}
            >
              3
            </Link>
            <Link
              to="/cirkel/delen-vrij/4"
              className="btn--blue btn--blue4"
              onMouseEnter={() => this.handleHover(".cls-5")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-5")}
              className={`btn--blue btn--blue4 ${
                activeDeel === 4 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 4 })}
            >
              4
            </Link>
            <Link
              to="/cirkel/delen-vrij/5"
              onMouseEnter={() => this.handleHover(".cls-2")}
              onMouseLeave={() => this.handleOnMouseLeave(".cls-2")}
              className={`btn--blue btn--blue5 ${
                activeDeel === 5 ? "btn--blue--active" : ""
              }`}
              onClick={() => this.setState({ activeDeel: 5 })}
            >
              5
            </Link>
            <div className="cirkel__model--logo"></div>
            <div className="cirkel__model--divider"></div>
          </div>
        </div>

        <Routes>
          <Route path="*" element={<CirkelAlleDelenVrij />}></Route>
          <Route exact path="1" element={<CirkelDeelVrij activeDeel={1} />} />
          <Route exact path="2" element={<CirkelDeelVrij activeDeel={2} />} />
          <Route exact path="3" element={<CirkelDeelVrij activeDeel={3} />} />
          <Route exact path="4" element={<CirkelDeelVrij activeDeel={4} />} />
          <Route exact path="5" element={<CirkelDeelVrij activeDeel={5} />} />
        </Routes>
      </div>
    );
  }
}

export default CirkelDelenVrij;
