import React, { Component } from "react";

class DeviceModal extends Component {
  render() {
    return (
      <div
        className="modal"
        id="device-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header"></div>
            <div className="modal-body">
              <h2>Oeps!</h2>
              <h4>Draai je scherm</h4>
              <h3 className="whiteHighlight whiteHighlight--small">of</h3>
              <h4>
                gebruik een{" "}
                <strong className="verhaaldeel__tekst--emphasis">laptop</strong>{" "}
                of{" "}
                <strong className="verhaaldeel__tekst--emphasis">tablet</strong>
              </h4>
              <h4>om deze pagina te bekijken</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceModal;
