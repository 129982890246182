import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../scss/variables.scss";
import Obfuscate from "react-obfuscate";

import logo from "../assets/cirkelhelden_logo.svg";
import cirkeltje3 from "../assets/cirkel_oranje_2.svg";
import cirkeltje4 from "../assets/cirkel_rood.svg";
import cirkeltje5 from "../assets/cirkel_roze.svg";
import sfeerbeeld1 from "../assets/images/ehc_ch_009.jpg";
import sfeerbeeld2 from "../assets/images/ehc_ch_022.jpg";
import logoBib from "../assets/logos/logo_bib.jpg";
import logoOetang from "../assets/logos/naked--no-grunge.png";

import { HashLink } from "react-router-hash-link";

class LandingPage extends Component {
  render() {
    return (
      <div id="landingPage">
        <header>
          <div className="navigation">
            <img
              className="cirkeltje cirkeltje4"
              alt="cirkeltje"
              src={cirkeltje4}
            ></img>

            <div className="navigation__left">
              <Link to="/">
                <img src={logo} alt="Logo van Cirkelhelden"></img>
              </Link>
            </div>
            <div className="navigation__right">
              <HashLink smooth to="#introduction" className="navigation__link">
                Over Cirkelhelden
              </HashLink>
              <HashLink smooth to="#indeklas" className="navigation__link">
                Het lespakket
              </HashLink>
              <a
                href="https://experienceantwerp.be/nl/aanbod/schoolbezoek/musea/erfgoedbibliotheek-hendrik-conscience/workshop-cirkelhelden-lager-onderwijs/"
                className="navigation__link navigation__link--highlight"
                target="__blank"
                rel="noreferrer"
              >
                Boek als workshop
              </a>
            </div>
          </div>
          <div className="hero">
            <img
              className="cirkeltje cirkeltje3"
              alt="cirkeltje"
              src={cirkeltje3}
            ></img>
            <img
              className="cirkeltje cirkeltje5"
              alt="cirkeltje"
              src={cirkeltje5}
            ></img>
            <h1>
              Een creatief le(e)spakket <br></br>over tijdloze
              <span className="whiteHighlight whiteHighlight--large">
                helden
              </span>{" "}
            </h1>
            <HashLink className="btn--red" smooth to="#indeklas">
              Ontdek de gratis lesmaterialen
            </HashLink>
          </div>
          <div className="cirkel__model--divider"></div>
        </header>
        <main>
          <section className="introduction" id="introduction">
            <aside className="introduction__aside">
              <img
                src={sfeerbeeld1}
                alt="Leerlingen voeren een foto-uitdaging uit."
              ></img>
              <img
                src={sfeerbeeld2}
                alt="Leerlingen vullen de verhaalcirkel in."
              ></img>
            </aside>
            <div className="introduction__body">
              <h2 className="introduction__title">
                Heldenverhalen ontdekken en creëren!
              </h2>
              <div className="introduction__tags">
                <span className="btn--tag">3e graad BaO / 1e jaar SO</span>
                <span className="btn--tag whiteHighlight">
                  20-30 leerlingen
                </span>
                <span className="btn--tag whiteHighlight">2 lesuren</span>
              </div>
              <p className="introduction__description">
                <span className="highlight">Cirkelhelden</span> neemt je mee in
                de wondere wereld van heldenverhalen van vroeger en nu. Je kunt
                Cirkelhelden op{" "}
                <span className="highlight">twee manieren beleven</span>: via
                dit{" "}
                <HashLink to="#indeklas">
                  <span>gratis lespakket</span>
                </HashLink>{" "}
                voor in de klas, of via een{" "}
                <a
                  href="https://consciencebibliotheek.be/nl/workshop-cirkelhelden"
                  target="__blank"
                  rel="noreferrer"
                >
                  <span>begeleide workshop</span>{" "}
                  <i className="icon-external-link"></i>
                </a>{" "}
                in de Erfgoed&shy;bibliotheek Hendrik Conscience, Antwerpen.
              </p>
              <p className="introduction__description">
                Samen ontdekken de leerlingen al doende wat helden&shy;verhalen
                meeslepend, spannend en tijdloos maakt. Aan de hand van
                uitdagende{" "}
                <span className="highlight">verbeeldings&shy;opdrachten</span>{" "}
                en een <span className="highlight">digitale tool</span> komen de
                leerlingen de typische (cirkel)&shy;structuur en ingrediënten
                van heldenverhalen op het spoor. Ze verwerken die tot een{" "}
                <span className="highlight">eigen heldenverhaal</span>.
              </p>
              <p className="introduction__description">
                Cirkelhelden zit boordevol heldhaftige verrassingen,
                samenwerking en creativiteit, en{" "}
                <span className="highlight">
                  maakt de leerlingen warm voor lezen
                </span>
                . Na afloop kunnen de leerlingen zelf een volgend heldenverhaal
                ontdekken aan de hand van een ware bibchallenge.
              </p>
            </div>
          </section>
          <section className="klasversie">
            <h2 className="klasversie__title" id="indeklas">
              Downloadbare les voor in de klas{" "}
            </h2>{" "}
            <section className="klasversie__body">
              <h3>Handleiding</h3>
              <div className="klasversie__kader">
                <p className="klasversie__tekst">
                  Download de printbare handleiding om de{" "}
                  <span className="highlight">opbouw</span> en{" "}
                  <span className="highlight">inhoud</span> van de workshop te
                  ontdekken. Tijdens de workshop zelf kan de handleiding dienen
                  als spiekblad!
                </p>
                <a
                  className="klasversie__btn"
                  href="./downloads/0_cirkelhelden_handleiding.pdf"
                  download="cirkelhelden_handleiding"
                >
                  <span>Download de handleiding</span>
                  <i className="icon-arrow-down-1"></i>
                </a>
              </div>
              <h3>Digitale presentatie</h3>
              <div className="klasversie__kader">
                <p className="klasversie__tekst">
                  Een klikbare presentatie leidt jou en je leerlingen langs de
                  nodige <span className="highlight">inzichten</span> over wat
                  typisch is voor heldenverhalen. De leerlingen kunnen ook een
                  digitale tool verkennen om{" "}
                  <span className="highlight">inspiratie</span> op te doen voor
                  hun eigen verhaal.
                </p>
                <div className="klasversie__btn-group">
                  <Link
                    className="klasversie__btn"
                    to="/cirkel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Start digibordles</span>{" "}
                    <i className="icon-external-link"></i>
                  </Link>
                  <Link
                    className="klasversie__btn"
                    to="/cirkel/delen-vrij"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Open leerlingentool</span>{" "}
                    <i className="icon-external-link"></i>
                  </Link>
                </div>
              </div>
              <h3>Printinstructies</h3>
              <div className="klasversie__kader">
                <h4>De heldeninstap</h4>
                <ul>
                  <li>
                    <a
                      href="./downloads/1_cirkelhelden_bronnen.pdf"
                      download="cirkelhelden_bijlage-1_bronnen"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Bijlage 1 - Bronnen</span>
                    </a>
                    <small>A4 - recto - 1x printen</small>
                  </li>
                  <li>
                    <a
                      href="./downloads/1_cirkelhelden_invulstrookje.pdf"
                      download="cirkelhelden_bijlage-2_invulstrookjes"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Bijlage 2 - Invulstrookje</span>
                    </a>
                    <small>A4 - recto - x aantal leerlingen / 3 </small>
                  </li>
                </ul>
              </div>
              <div className="klasversie__kader">
                {" "}
                <h4>Heldenverhalen onderzoeken en verzamelen</h4>
                <ul>
                  <li>
                    <a
                      href="./downloads/2_cirkelhelden_fragmenten.pdf"
                      download="cirkelhelden_bijlage-3_fragmenten"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Bijlage 3 - Fragmenten</span>
                    </a>
                    <small>A4 - recto - 1x printen</small>
                  </li>
                  <li>
                    <a
                      href="./downloads/2_cirkelhelden_verbeeldingschallenges.pdf"
                      download="cirkelhelden_bijlage-4_uitdagingen"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Bijlage 4 - Uitdagingen</span>
                    </a>
                    <small>A4 - recto - selectie 1x printen </small>
                  </li>
                </ul>
              </div>
              <div className="klasversie__kader">
                {" "}
                <h4>Een eigen heldenverhaal verzinnen</h4>
                <ul>
                  <li>
                    <a
                      href="./downloads/4_cirkelhelden_invulcirkel.pdf"
                      download="cirkelhelden_bijlage-5_invulcirkel"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Bijlage 5 - Verhaalcirkel</span>
                    </a>
                    <small>A3 - recto - x aantal leerlingen / 2 </small>
                  </li>
                </ul>
              </div>
              <div className="klasversie__kader">
                {" "}
                <h4>Vervolgopdracht in de lokale bib</h4>
                <ul>
                  <li>
                    <a
                      href="./downloads/4_cirkelhelden_invulcirkel.pdf"
                      download="cirkelhelden_bijlage-5_invulcirkel"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Bijlage 5 - Verhaalcirkel</span>
                    </a>
                    <small>A3 - recto - x aantal leerlingen </small>
                  </li>
                  <li>
                    <a
                      href="./downloads/5_cirkelhelden_bibchallenge.pdf"
                      download="cirkelhelden_bijlage-6_bibchallenge"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Bijlage 6 - Bibchallenge</span>
                    </a>
                    <small>A4 - recto - x aantal leerlingen</small>
                  </li>
                </ul>
              </div>{" "}
              <h3>Doelen</h3>
              <div className="klasversie__kader">
                <ul>
                  <li>
                    <a
                      href="./downloads/0_cirkelhelden_doelen_eindtermen-basisonderwijs.pdf"
                      download="cirkelhelden_doelen_eindtermen-basisonderwijs"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Eindtermen basisonderwijs</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="./downloads/0_cirkelhelden_doelen_leerplandoelen-zill.pdf"
                      download="cirkelhelden_doelen_leerplandoelen-zill"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>
                        Leerplandoelen ZILL Katholiek Onderwijs Vlaanderen
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="./downloads/0_cirkelhelden_doelen_sleutelcompetenties-SO.pdf"
                      download="cirkelhelden_doelen_sleutelcompetenties-so"
                    >
                      <div className="klasversie__btn">
                        <i className="icon-arrow-down-1"></i>
                      </div>
                      <span>Sleutelcompetenties en doelen SO A/B-stroom</span>
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </section>
        </main>
        <div className="cirkel__model--divider"></div>
        <div className="divider--space"></div>
        <footer>
          <div className="footer__tekst">
            <p>
              Heb je nog vragen over de workshop, maak je graag een afspraak om
              het materiaal op locatie te ontdekken of wil je meteen een
              workshop boeken in de Erfgoedbibliotheek? Bezoek dan{" "}
              <a
                href="https://consciencebibliotheek.be/nl/workshop-cirkelhelden"
                target="__blank"
                rel="noreferrer"
              >
                onze website
              </a>{" "}
              of mail naar de{" "}
              <Obfuscate
                email="educatie_ehc@antwerpen.be"
                headers={{
                  subject: "Workshop Cirkelhelden boeken",
                }}
              >
                educatieve&nbsp;dienst
              </Obfuscate>{" "}
              van de Erfgoedbibliotheek Hendrik Conscience.
            </p>
          </div>
          <div className="footer__logos">
            <a
              href="https://consciencebibliotheek.be/nl"
              target="__blank"
              rel="noreferrer"
            >
              <img
                src={logoBib}
                alt="Logo van de Erfgoedbibliotheek Hendrik Conscience"
              ></img>
            </a>
            <a href="https://oetang.be/" rel="noreferrer">
              <img
                src={logoOetang}
                alt="Logo van Oetang Learning Designers"
              ></img>
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

export default LandingPage;
