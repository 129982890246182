import React, { Component } from "react";

class CirkelAlleDelenVrij extends Component {
  render() {
    return (
      <div className="uitleg uitleg--vrij">
        <div className="uitleg__info">
          <h1 className="uitleg__titel">
            Klik op een verhaaldeel voor{" "}
            <span className="whiteHighlight">inspiratie</span>
          </h1>
        </div>
      </div>
    );
  }
}

export default CirkelAlleDelenVrij;
