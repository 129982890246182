import React, { Component } from "react";
import { Link } from "react-router-dom";

class CirkelVerhaal extends Component {
  constructor(props) {
    super(props);

    const images = [
      "../../assets/digicirkel_verhaal_1.svg",
      "../../assets/digicirkel_verhaal_2.svg",
      "../../assets/digicirkel_verhaal_3.svg",
      "../../assets/digicirkel_verhaal_4.svg",
      "../../assets/digicirkel_verhaal_5.svg",
    ];

    this.state = {
      images,
      currentImg: 3,
      currentImgUrl: images[3],
    };
  }

  componentDidMount() {
    if (this.state.images !== null) {
      this.interval = setInterval(() => this.changeBackgroundImage(), 1500);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  changeBackgroundImage() {
    let newCurrentImg = 0;
    let cirkelBackground = document.querySelector(".cirkel__model--verhaal");
    const { images, currentImg } = this.state;
    const noOfImages = images.length;

    if (currentImg !== noOfImages - 1) {
      newCurrentImg = currentImg + 1;
    }

    this.setState({ currentImg: newCurrentImg });

    cirkelBackground.classList.remove(
      "cirkel__model--cirkelBackground1",
      "cirkel__model--cirkelBackground2",
      "cirkel__model--cirkelBackground3",
      "cirkel__model--cirkelBackground4",
      "cirkel__model--cirkelBackground0"
    );
    cirkelBackground.classList.add(
      `cirkel__model--cirkelBackground${currentImg}`
    );
  }

  render() {
    const { images, currentImg, currentImgUrl } = this.state;

    return (
      <div>
        <div className="cirkel">
          <div className="cirkel__model cirkel__model--verhaal cirkel__model--cirkelBackground3">
            <div className="cirkel__model--logo"></div>
            <div className="cirkel__model--divider"></div>
          </div>
        </div>
        <div className="uitleg">
          <div className="uitleg__info">
            <h1 className="uitleg__titel">
              <span className="whiteHighlight whiteHighlight--small">5</span>{" "}
              heldenverhalen
            </h1>
            <p className="uitleg__tekst">
              Heldenverhalen hebben vaak een cirkelstructuur: helden trekken
              eropuit en komen weer thuis.
            </p>
            <p className="uitleg__tekst">
              De Erfgoedbibliotheek bewaart makkelijk{" "}
              <span className="uitleg__tekst--bold">
                {" "}
                duizenden heldenverhalen
              </span>
              , in verschillende talen!
            </p>
          </div>
          <div className="btn-group">
            <Link to="/cirkel" className="btn--red uitleg__btn">
              <i className="icon-angle-left"></i>
              Vorige
            </Link>
            <Link to="/cirkel/tijd" className="btn--red uitleg__btn">
              Volgende
              <i className="icon-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CirkelVerhaal;
