import { HashRouter, Route, Routes } from "react-router-dom";
import Bron1 from "./components/cirkeltool/bronnen/Bron1";
import Bron2 from "./components/cirkeltool/bronnen/Bron2";
import Bron3 from "./components/cirkeltool/bronnen/Bron3";
import Bron4 from "./components/cirkeltool/bronnen/Bron4";
import Bron5 from "./components/cirkeltool/bronnen/Bron5";
import Cirkeltool from "./components/cirkeltool/Cirkeltool";
import LandingPage from "./pages/LandingPage";
import Page404 from "./pages/Page404";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route exact path={("", "/")} element={<LandingPage />}></Route>
          <Route
            path={("/cirkel", "/cirkel/*")}
            element={<Cirkeltool></Cirkeltool>}
          ></Route>
          <Route path={"/bron1"} element={<Bron1 />} />
          <Route path={"/bron2"} element={<Bron2 />} />
          <Route path={"/bron3"} element={<Bron3 />} />
          <Route path={"/bron4"} element={<Bron4 />} />
          <Route path={"/bron5"} element={<Bron5 />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
